import React from 'react';
import { Link, graphql } from "gatsby"

import Box from '@mui/material/Box';
import { ThemeProvider} from '@material-ui/core/styles'
import {createTheme} from '@mui/material/styles'

import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Layout from "../components/layout"

import Slide from 'react-reveal/Slide';

let history = require('../dat_files/workhistory.json')

let arr = []

let theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
          main: '#0c1021',
        },
        secondary: {
          main: '#00e676',
        },
      },
      props: {
        MuiTooltip: {
          arrow: true,
        },
      },
    
  })


const skillCard = ({skill}) =>{
    return(
        <Box>
            {skill}
        </Box>
    )
}

const placesIWorked = (job, key) => {
    return(
        <Slide key={key} right>
        <Box
        component="div"
        sx={{
            bgcolor: theme.palette.primary.main, 
            width: '70%',              
            padding: '5%',
            margin: '2%',   
            boxShadow: '.3em .3em .3em .3em'       
        }}
        >
            <Box 
            component="h1"
            >
                {job.place}
            </Box>
            <Box 
            component="h2"
            
            >
                {job.title}
            </Box>
            <Box 
            component="h6"
            
            >
                {job.duration}
            </Box>
            <Box 
            component="h6"            
            >
                {job.jobDesc}
            </Box>
        </Box>
        </Slide>
    )
}

const extractworkHist = () => {
    if(!history) return
    if (arr.length > 1) return arr

    for(let i = 0; i < history.job.length; i++) {
        arr.push(placesIWorked(history.job[i], ('workCard'+i)))
    }
    return(
        [arr]
    )
}

const Resume = ({ data, location}) => {
    const siteTitle = data.site.siteMetadata.title 
    const containerRef = React.useRef(null)
    
  return(
    <ThemeProvider theme={theme}>
      <Layout location={location} title={siteTitle} >
          
          <Paper 
          style={{width: '60vw', height: 'auto',          
          
        }}
          elevation={3}
          sx={{
                bgcolor: theme.palette.primary.light,                
            }}
          >
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',                                  
              }}>
                  <Box component='h1'>Work Experience</Box>
                  {extractworkHist()}

              </Box>
          </Paper>
      </Layout>
      </ThemeProvider>

    )
};

export default Resume;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
